import React from 'react';
import { Button, Icon, Form, Input } from 'antd';
import { debug, warning } from './Utilities'
import { UserContainer } from './User';
import AdminContainer from './Admin';
import axios from 'axios';

var qs = require('qs');

const FormItem = Form.Item;

class CheckIn extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      id: 0,
      ruolo: ''
    };

  }

  componentDidMount() {

    axios({
      method:'post',
      url:'php/checkLog.php',
      data: '',
      responseType:'text'
    })
    .then(function(response) {
      this.setState({id: response.data.id, ruolo: response.data.ruolo});
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  handleLogID = (id) => { this.setState({id: id}); }
  handleLogRole = (ruolo) => { this.setState({ruolo: ruolo}); }

  render() {

    if(debug) console.log('CheckLog');

    if (this.state.ruolo==='') return (null);

    if (this.state.ruolo==='none') return (<Login id={this.handleLogID} ruolo={this.handleLogRole} />);

    let container = '';
    let logout = <Logout ruolo={this.handleLogRole} />

    if (this.state.ruolo==='user' ) {
      logout = ''; //logout user solo sulla home (gestito in UserContainer)
      container = <UserContainer  uid={this.state.id} />
    }
    if (this.state.ruolo==='tutor') container = <AdminContainer uid={this.state.id} ruolo={this.state.ruolo} />
    if (this.state.ruolo==='admin') container = <AdminContainer uid={this.state.id} ruolo={this.state.ruolo} />

    return (
      <div>
        {container}
        {logout}
      </div>
    );

  }
}

/* form login *************************************************************** */

class Login extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      username:'',
      password:'',
    };

  }

  onChangeUser = (e) => {
    this.setState({username: e.target.value});
  }
  onChangePass = (e) => {
    this.setState({password: e.target.value});
  }

  checkIn = (e) => {

    e.preventDefault();

    if (this.state.username==='' || this.state.password==='') {
      warning('Digita username e password.');
      return;
    }
    axios({
      method:'post',
      url:'php/login.php',
      data: qs.stringify({ur: this.state.username, pw: this.state.password}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        this.props.id(response.data.id);
        this.props.ruolo(response.data.ruolo);
      } else {
        warning('Username o password errate.');
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  render() {

    if(debug) console.log('Login');

    return (
      <Form onSubmit={this.checkIn} className="login-form">
        <FormItem>
          <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} onChange={this.onChangeUser} value={this.state.username} type="text" placeholder="username" />
        </FormItem>
        <FormItem>
          <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} onChange={this.onChangePass} value={this.state.password} type="password" placeholder="password" />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="login-form-button">Login</Button>
        </FormItem>
      </Form>
    );
  }
}

/* logout ******************************************************************* */

class Logout extends React.Component {

  doLogout = () => {
    axios({
      method:'post',
      url:'php/logout.php',
      data: '',
      responseType:'text'
    })
    .then(function(response) {
      this.props.ruolo('none');
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  render() {

    if(debug) console.log('Logout');

    return (<Button id="btn-logout" type="primary" onClick={this.doLogout} className="logout-button">Logout</Button>);
  }

}

//export default CheckIn;
export {CheckIn,Logout};
