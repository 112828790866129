import { notification } from 'antd';

const debug = false;

const warning = (msg,err) => {

  let error = err; //'';
  if (err==="Network Error") error = 'Verifica la tua connessione a internet.';

  //if (err==='logout') error = <div dangerouslySetInnerHTML={createMarkup('<a href="javascript:location.reload()">Login</a>')} />

  const args = {
    message: msg,
    description: error,
    duration: 4,
  };

  notification.open(args);
}

//function createMarkup(html) { return {__html: html}; };

export {debug, warning};
