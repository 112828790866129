import React from 'react';
import { Row, Col, Collapse, Table, Form, Input, InputNumber, Select, Switch, Icon, Button, Popconfirm, Modal } from 'antd';
import axios from 'axios';
import jsPDF from 'jspdf';
import Chart from 'chart.js';
import { debug, warning } from './Utilities'

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
/*
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
*/
import {Preview} from './User.js';

const { Column } = Table;
const { TextArea } = Input;
const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;

var qs = require('qs');

function rnd() { return Math.floor(Math.random()*10) }

//function createMarkup(html) { return {__html: html}; };

class EditProgetti extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      azione:'list',
      stats:false,
      tracking:false,
      record:false,
      dataSource: false,
      count: 0,
    };
  }

  componentDidMount() {

    if (debug) console.log('EditProgetti - componentDidMount')
    this.getData();

  }

  getData = () => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get'}),
      responseType:'text'
    })
    .then(function(response) {
      //if (response.data==='logout') { warning('Sessione scaduta','logout'); return; }
      if (response.data!=='') {
        for (let ii=0; ii<response.data.length; ii++) response.data[ii].key = ii;
        this.setState({ dataSource: response.data, count: response.data.length, azione: 'list' });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  onCopy = (key,id) => {

    //const dataSource = [...this.state.dataSource];

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'copy', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.getData();
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onDelete = (key,id) => {

    const dataSource = [...this.state.dataSource];

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'del', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onEdit = (record) => {

    this.setState({azione:'edit', record:record});

  }

  onStats = (pid, chart_type) => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_stats', pid:pid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        if (response.data.length!==0) {
          this.setState({azione:'stats', chart_type:chart_type, stats:response.data});
        } else {
          warning('Non c\'è nessun dato da visualizzare!');
        }
      } else {
        warning('Ops, qualcosa è andato storto...');
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  onTrack = (pid) => {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_tracking', pid:pid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        if (response.data.length!==0) {
          this.setState({azione:'tracking_clickme', tracking:response.data});
        } else {
          warning('Non c\'è nessun dato da visualizzare!');
        }
      } else {
        warning('Ops, qualcosa è andato storto...');
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  onNew = () => {

    this.setState({azione:'new'});
  }

  doBack = () => {
    this.getData();
  }

  onChangeState = (id,checked) => {
    let stato = checked===true?1:0;
    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'upd_stato', id: id, stato: stato}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') warning('Ops, qualcosa è andato storto...');
    })
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  preview = (pid) => {

    Modal.info({
      title: 'PREVIEW',
      width: '64rem',
      content: (<Preview uid={this.props.uid} pid={pid} />),
      okText: 'Chiudi',
    });

  }

  render() {

    if (debug) console.log('EditProgetti - render')

    const { dataSource } = this.state;

    if (dataSource) {

      let html = [];

      let nuovo = <a onClick={this.onNew}><Icon type="plus-circle-o" /></a>
      let colonna_edit = '';
      let colonna_copy = '';
      let colonna_new_del = '';

      if (this.props.ruolo==='admin') {

        colonna_edit = <Column title="" key="edit" width="4%" render={(text, record) => ( <a onClick={() => this.onEdit(record)}><Icon type="edit" /></a> )} />

        colonna_copy =
          <Column title="" key="copy" width="4%"
                  render={(text, record) => (
                    <Popconfirm title="Confermi?" onConfirm={() => this.onCopy(record.key,record.id)}>
                      <a href="#"><Icon type="copy" /></a>
                    </Popconfirm>
            )}
          />

        colonna_new_del =
          <Column title={nuovo} key="delete" width="4%"
                  render={(text, record) => (
                    <Popconfirm title="Confermi?" onConfirm={() => this.onDelete(record.key,record.id)}>
                      <a href="#"><Icon type="delete" /></a>
                    </Popconfirm>
            )}
          />
      }

      if (this.state.azione==='list')
        html =
          <div>
            <Table bordered pagination={true} dataSource={dataSource}>
              <Column title="Titolo" dataIndex="titolo" key="titolo" />
              <Column title="Rif. SIA" dataIndex="riferimento" key="riferimento" width="10%" />
              <Column title="Attivo" key="stato" width="6%"
                      render={(text, record) => ( <Switch defaultChecked={record.stato==='1'?true:false} onChange={(checked) => this.onChangeState(record.id,checked)} /> )}
              />
              {colonna_edit}
              {colonna_copy}
              <Column title="" key="preview" width="4%"
                      render={(text, record) => ( <a onClick={() => this.preview(record.id)}><Icon type="play-circle-o" /></a> )}
              />
              <Column title="" key="tracking_clickme" width="4%"
                      render={(text, record) => ( <a onClick={() => this.onTrack(record.id)}><Icon type="solution" /></a> )}
              />
              <Column title="" key="statistiche1" width="4%"
                      render={(text, record) => ( <a onClick={() => this.onStats(record.id,'bar')}><Icon type="bar-chart" /></a> )}
              />
              <Column title="" key="statistiche2" width="4%"
                      render={(text, record) => ( <a onClick={() => this.onStats(record.id,'pie')}><Icon type="pie-chart" /></a> )}
              />
              {colonna_new_del}
            </Table>
          </div>

      if (this.state.azione==='new')
        html = <EditProgetto data={null} back={this.doBack} uid={this.props.uid} />

      if (this.state.azione==='edit')
        html = <EditProgetto data={this.state.record} back={this.doBack} uid={this.props.uid} />

      if (this.state.azione==='tracking_clickme') {
        html[0] = <Button key={0} className="spazia" onClick={this.doBack}>Indietro</Button>
        html[1] = <GetTrackingClickme key={1} data={this.state.tracking} back={this.doBack} />
      }

      if (this.state.azione==='stats') {

        let jj = 0;
        let kk = 0;
        let data = [];
        let id = this.state.stats[0].id;

        html[0] = <Button key={0} className="spazia" onClick={this.doBack}>Indietro</Button>

        for (let ii=0; ii<this.state.stats.length; ii++) {

          if (parseInt(this.state.stats[ii].id,10)===parseInt(id,10)) data[jj++] = this.state.stats[ii];

          if (parseInt(this.state.stats[ii].id,10)!==parseInt(id,10) || ii===this.state.stats.length-1) {

            html[++kk] = <GetStats key={kk} chart_type={this.state.chart_type} data={data} />
            jj = 0;
            data = [];
            data[jj++] = this.state.stats[ii];
            id = this.state.stats[ii].id;
          }
        }

      }

      return (
        <React.Fragment>
          {html}
        </React.Fragment>
      );
    }
    else { return (<Icon type="loading" style={{ color: '#d6a54b', fontSize: 48 }} />) }
  }
}

class EditProgetto extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      utenti: [],
      progetti_sia: [],
      riferimento: 'no'
    };

    if (this.props.data!=null)
      this.state = {
        utenti: [],
        progetti_sia: [],
        id: this.props.data.id,
        stato: this.props.data.stato,
        titolo: this.props.data.titolo,
        descrizione: this.props.data.descrizione,
        riferimento: this.props.data.riferimento,
      };
  }

  componentDidMount() {
    if (debug) console.log('EditProgetto - componentDidMount')
    this.getProgettiSia();
    if (this.props.data!=null) this.getUtenti();
  }

  onChangeField = (e) => {

    if (e.target.name==='titolo') this.setState({titolo: e.target.value});
    if (e.target.name==='descrizione') this.setState({descrizione: e.target.value});
    //if (e.target.name==='riferimento') this.setState({riferimento: e.target.value});
  }

  onChangeCAR = (value,e) => {this.setState({riferimento:e.props.value, titolo:e.props.children}); }

  back = () => {
    this.props.back(true);
  }

  save = (e) => {

    e.preventDefault();

    let azione = (this.state.id===0) ? 'new' : 'upd';

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:azione, id: this.state.id, titolo: this.state.titolo, descrizione: this.state.descrizione, riferimento: this.state.riferimento}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        if (this.state.id===0) this.setState({id: response.data});
        warning("Dati salvati correttamente.");
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  getUtenti = () => {
    axios({
      method:'post',
      url:'php/utenti.php',
      data: qs.stringify({azione:'project', id_progetto:this.state.id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        for (let ii=0; ii<response.data.length; ii++) response.data[ii].key = ii;
        this.setState({ utenti: response.data });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  getProgettiSia = () => {
    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_progetti_sia'}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        this.setState({ progetti_sia: response.data });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  inizializza = () => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'init', id_progetto:this.state.id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        warning('Progetto inizializzato correttamente.')
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    })
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  password = () => {

    if (this.state.utenti.length===0) {
      warning('Devi prima importare gli utenti.');
      return;
    }
    let data = '';
    for (let ii=0; ii<this.state.utenti.length; ii++) {
      this.state.utenti[ii].password = rnd()+''+rnd()+''+rnd()+''+rnd()+''+rnd()+''+rnd()+'';
      data += this.state.utenti[ii].id+','+this.state.utenti[ii].password+'/';
    }
    axios({
      method:'post',
      url:'php/utenti.php',
      data: qs.stringify({azione:'password', data:data}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...');
      } else {
        warning("Password generate correttamente. Adesso esporta gli utenti e conserva il file.");
        this.setState({ utenti: this.state.utenti });
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  importa = () => {

    axios({
      method:'post',
      url:'php/utenti.php',
      data: qs.stringify({azione:"import", riferimento: this.state.riferimento, id_progetto: this.state.id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...', 'Forse non ci sono utenti associati!')
      } else {
        warning("Dati importati correttamente. Adesso genera le password.");
        this.getUtenti();
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  esporta = () => {

    if (this.state.utenti.length===0) {
      warning("Devi prima importare gli utenti e generare le password.");
      return;
    }

  	var riga = 10;
  	var pdf = new jsPDF();
	  var num_righe = 0;
  	pdf.setFont("helvetica");
  	pdf.setFontSize(12);

  	for (let ii=0; ii<this.state.utenti.length; ii++) {
		   if (num_righe>=27) { pdf.addPage(); riga = 10; num_righe = 0;  }
  	   pdf.text(5, riga, (''+this.state.utenti[ii].gruppo));
  	   pdf.text(15, riga, this.state.utenti[ii].nome);
  	   pdf.text(65, riga, this.state.utenti[ii].cognome);
  	   pdf.text(115, riga, this.state.utenti[ii].username);
  	   pdf.text(185, riga, this.state.utenti[ii].password);
       riga = riga+10;
		   num_righe++;
    }
    pdf.save(this.state.riferimento+'.pdf')
  }

  elimina = () => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:"del_gruppi_by_project", id_progetto: this.state.id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        warning("Utenti iscritti cancellati correttamente.");
        this.getUtenti();
      } else {
        warning('Ops, qualcosa è andato storto...');
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  preview = () => {

    Modal.info({
      title: 'PREVIEW',
      width: '64rem',
      content: (<Preview uid={this.props.uid} pid={this.state.id} />),
      okText: 'Chiudi',
    });

  }

  render() {

    if (debug) console.log('EditProgetto - render')

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 22 },
      },
    };

    let progetti_sia = [];
    for (let ii=0; ii<this.state.progetti_sia.length; ii++) progetti_sia[ii] = <Option key={ii+1} value={this.state.progetti_sia[ii].car}>{this.state.progetti_sia[ii].titolo+' - '+this.state.progetti_sia[ii].info_attestato}</Option>

    let impostazioni =
      <Form id="the-form" onSubmit={this.save}>
        <FormItem {...formItemLayout} label="Titolo:">
          <Input onChange={this.onChangeField} name="titolo" value={this.state.titolo} type="text" placeholder="Titolo del progetto" required />
        </FormItem>
        <FormItem {...formItemLayout} label="Rif. SIA:">
          <Select defaultValue={this.state.riferimento} onChange={this.onChangeCAR}>
            <Option key='0' value='no'>No</Option>
            {progetti_sia}
          </Select>
        </FormItem>
        <FormItem {...formItemLayout} label="Descrizione:">
          <TextArea onChange={this.onChangeField} name="descrizione" value={this.state.descrizione} placeholder="Descrizione del progetto" autosize />
        </FormItem>
        <FormItem>
          <Row gutter={16}>
            <Col xs={24} sm={8}>
              <Popconfirm title="Eliminazione di tutti i dati di sessione degli iscritti. Confermi?" onConfirm={() => this.inizializza()}><Button type="primary">Inizializza</Button></Popconfirm>
            </Col>
            <Col xs={24} sm={8}><Button type="primary" onClick={this.preview}>Preview</Button></Col>
            <Col xs={24} sm={8}><Button type="primary" htmlType="submit">Salva</Button></Col>
          </Row>
        </FormItem>
      </Form>

    let utenti = '';
    if (this.state.id!==0)
      utenti =
        <div>
          <GetUtenti data={this.state.utenti} id_progetto={this.state.id} />
          <Row gutter={16}>
            <Col xs={24} sm={6}><Button type="primary" onClick={this.importa}>Importa utenti da SIA</Button></Col>
            <Col xs={24} sm={6}><Button type="primary" onClick={this.password}>Genera password</Button></Col>
            <Col xs={24} sm={6}><Button type="primary" onClick={this.esporta}>Esporta utenti</Button></Col>
            <Col xs={24} sm={6}>
              <Popconfirm title="Stai per cancellare solo le iscrizioni, utenti e votazioni non verranno eliminati. Confermi?" onConfirm={() => this.elimina()}><Button type="primary">Cancella iscrizioni</Button></Popconfirm>
            </Col>
          </Row>
        </div>

    let casi = '';
    if (this.state.id!==0) casi = <GetCasi pid={this.state.id} />

    return (
      <div>
        <Button className="spazia" onClick={this.back}>Indietro</Button>
        <Collapse accordion bordered={false} defaultActiveKey={['1']}>
          <Panel className="panel" header="Impostazioni generali" key="1">{impostazioni}</Panel>
          <Panel className="panel" header="Casi" key="2">{casi}</Panel>
          <Panel className="panel" header="Utenti" key="3">{utenti}</Panel>
        </Collapse>
      </div>
    );
  }
}

class GetUtenti extends React.Component {

  updateGruppo = (gruppo,id_utente,key) => {

    this.props.data[key].gruppo = gruppo;

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'upd_gruppo', id_progetto:this.props.id_progetto, id_utente:id_utente, id_gruppo:gruppo}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') warning('Ops, qualcosa è andato storto...')
    })
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  render() {

    if (debug) console.log('ListaUtenti - render')

    return (
      <Table className="spazia" bordered size={'small'} pagination={true} dataSource={this.props.data}>
        <Column title="Gruppo" key="gruppo"
          render={(text, record) => (
            <InputNumber size="large" min={0} max={10} defaultValue={record.gruppo} onChange={(value) => this.updateGruppo(value,record.id,record.key)} />
          )}
        />
        <Column title="Nome" dataIndex="nome" key="nome" />
        <Column title="Cognome" dataIndex="cognome" key="cognome" />
        <Column title="Username" dataIndex="username" key="username" />
        <Column title="Password" dataIndex="password" key="password" />
      </Table>
    );
  }
}

class GetCasi extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      azione:'list',
      record:false,
      dataSource: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_casi', pid: this.props.pid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        for (let ii=0; ii<response.data.length; ii++) response.data[ii].key = ii;
        this.setState({ dataSource: response.data, count: response.data.length, azione: 'list' });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onDelete = (key,id,tipo) => {
    const dataSource = [...this.state.dataSource];
    let url = 'php/progetti.php';
    if (tipo==='clickme') url = 'php/clickme.php';
    axios({
      method:'post',
      url:url,
      data: qs.stringify({azione:'del_caso', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onEdit = (record) => {
    this.setState({azione:'edit', record:record});
  }

  onNew = () => {
    this.setState({azione:'new'});
  }

  doBack = (back) => {
    this.getData();
  }

  render() {

    if (debug) console.log('GetCasi - render')

    const { dataSource } = this.state;

    if (dataSource) {
      let html = [];
      let nuovo = <a onClick={this.onNew}><Icon type="plus-circle-o" /></a>
      if (this.state.azione==='list')
        html =
          <Table bordered size={'small'} pagination={false} dataSource={dataSource}>
            <Column title="Titolo" dataIndex="titolo" key="titolo" />
            <Column title="Autore" dataIndex="autore" key="autore" />
            <Column title="" key="edit" width="5%"
              render={(text, record) => (
                <a onClick={() => this.onEdit(record)}><Icon type="edit" /></a>
              )}
            />
            <Column title={nuovo} key="delete" width="5%"
              render={(text, record) => (
                <Popconfirm title="Confermi?" onConfirm={() => this.onDelete(record.key,record.id,record.tipo)}>
                  <a href="#"><Icon type="delete" /></a>
                </Popconfirm>
              )}
            />
          </Table>

      if (this.state.azione==='new')
        html = <EditCaso pid={this.props.pid} data={null} back={this.doBack} />

      if (this.state.azione==='edit')
        html = <EditCaso data={this.state.record} back={this.doBack} />

      return (
        <React.Fragment>{html}</React.Fragment>
      );
    }
    else { return (<Icon type="loading" style={{ color: '#d6a54b', fontSize: 48 }} />) }
  }
}

class EditCaso extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      tipo: 'default'
    };

    if (this.props.data!=null)
      this.state = {
        id: this.props.data.id,
        titolo: this.props.data.titolo,
        autore: this.props.data.autore,
        tipo: this.props.data.tipo,
      };

  }

  onChangeField = (e) => {
    if (e.target.name==='titolo') this.setState({titolo: e.target.value});
    if (e.target.name==='autore') this.setState({autore: e.target.value});
  }

  onChangeTipo = (value,e) => {this.setState({tipo:e.props.value}); }

  back = () => {
    this.props.back(true);
  }

  save = (e) => {

    console.log('this.state.autore='+this.state.autore)

    e.preventDefault();

    let azione = (this.state.id===0) ? 'new_caso' : 'upd_caso';

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:azione, pid: this.props.pid, id: this.state.id, titolo: this.state.titolo, autore: this.state.autore, tipo: this.state.tipo}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        if (this.state.id===0) this.setState({id: response.data});
        warning("Dati salvati correttamente.");
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  render() {

    if (debug) console.log('EditCaso - render')

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 22 },
      },
    };

    let contenuti_caso = '';

    if (this.state.id!==0 && this.state.tipo==='default')
      contenuti_caso =
        <div className="box-editor">
          <h3>Sezioni</h3>
          <GetSezioni cid={this.state.id} />
        </div>

    if (this.state.id!==0 && this.state.tipo==='clickme')
      contenuti_caso =
        <Collapse accordion bordered={false} defaultActiveKey={['1']}>
          <Panel className="panel" header="Scenari Click Me" key="1"><GetScenariClickme cid={this.state.id} /></Panel>
          <Panel className="panel" header="Trattamenti Click Me" key="2"><GetTerapieClickme cid={this.state.id} /></Panel>
        </Collapse>
        /*
        <React.Fragment>
          <div className="box-editor">
            <h3>Scenari Click Me</h3>
            <GetScenariClickme cid={this.state.id} />
          </div>
          <div className="box-editor">
            <h3>Trattamenti Click Me</h3>
            <GetTerapieClickme cid={this.state.id} />
          </div>
        </React.Fragment>
        */
    return (
    <div>
      <Form id="the-form" onSubmit={this.save}>
        <FormItem {...formItemLayout} label="Titolo:">
          <Input onChange={this.onChangeField} name="titolo" value={this.state.titolo} type="text" placeholder="Titolo del caso" required />
        </FormItem>
        <FormItem {...formItemLayout} label="Autore:">
          <Input onChange={this.onChangeField} name="autore" value={this.state.autore} type="text" placeholder="Autore del caso" required />
        </FormItem>
        <FormItem {...formItemLayout} label="Tipo:">
          <Select defaultValue={this.state.tipo} onChange={this.onChangeTipo}>
            <Option key='0' value='default'>Default</Option>
            <Option key='1' value='clickme'>Click Me</Option>
          </Select>
        </FormItem>
        <FormItem>
          <Row gutter={16}>
            <Col xs={24} sm={12}><Button type="primary" onClick={this.back}>Chiudi</Button></Col>
            <Col xs={24} sm={12}><Button type="primary" htmlType="submit">Salva</Button></Col>
          </Row>
        </FormItem>
      </Form>
      {contenuti_caso}
    </div>
    );
  }
}

class GetSezioni extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      azione:'list',
      record:false,
      dataSource: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_sezioni', cid: this.props.cid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        for (let ii=0; ii<response.data.length; ii++) response.data[ii].key = ii;
        this.setState({ dataSource: response.data, count: response.data.length, azione: 'list' });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onDelete = (key,id) => {
    const dataSource = [...this.state.dataSource];
    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'del_sezione', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onEdit = (record) => {
    this.setState({azione:'edit', record:record});
  }

  onNew = () => {
    this.setState({azione:'new'});
  }

  doBack = (back) => {
    this.getData();
  }

  render() {

    if (debug) console.log('GetSezioni - render')

    const { dataSource } = this.state;

    if (dataSource) {
      let html = [];
      let nuovo = <a onClick={this.onNew}><Icon type="plus-circle-o" /></a>
      if (this.state.azione==='list')
        html =
          <Table bordered size={'small'} pagination={false} dataSource={dataSource}>
            <Column title="Titolo" dataIndex="titolo" key="titolo" />
            <Column title="" key="edit" width="5%"
              render={(text, record) => (
                <a onClick={() => this.onEdit(record)}><Icon type="edit" /></a>
              )}
            />
            <Column title={nuovo} key="delete" width="5%"
              render={(text, record) => (
                <Popconfirm title="Confermi?" onConfirm={() => this.onDelete(record.key,record.id)}>
                  <a href="#"><Icon type="delete" /></a>
                </Popconfirm>
              )}
            />
          </Table>

      if (this.state.azione==='new')
        html = <EditSezione cid={this.props.cid} data={null} back={this.doBack} />

      if (this.state.azione==='edit')
        html = <EditSezione data={this.state.record} back={this.doBack} />

      return (
        <React.Fragment>{html}</React.Fragment>
      );
    }
    else { return (<Icon type="loading" style={{ color: '#d6a54b', fontSize: 48 }} />) }
  }
}

class EditSezione extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: 0,
    };

    if (this.props.data!=null)
      this.state = {
        id: this.props.data.id,
        titolo: this.props.data.titolo,
      };

  }

  onChangeField = (e) => {
    if (e.target.name==='titolo') this.setState({titolo: e.target.value});
  }

  back = () => {
    this.props.back(true);
  }

  save = (e) => {

    e.preventDefault();

    let azione = (this.state.id===0) ? 'new_sezione' : 'upd_sezione';

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:azione, cid: this.props.cid, id: this.state.id, titolo: this.state.titolo}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        if (this.state.id===0) this.setState({id: response.data});
        warning("Dati salvati correttamente.");
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  render() {

    if (debug) console.log('EditSezione - render')

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 22 },
      },
    };

    let lista_sezioni = '';
    if (this.state.id!==0)
      lista_sezioni =
        <div className="box-editor">
          <h3>Scenari</h3>
          <GetScenari sid={this.state.id} />
        </div>

    return (
    <div>
      <Form id="the-form" onSubmit={this.save}>
        <FormItem {...formItemLayout} label="Titolo:">
          <Input onChange={this.onChangeField} name="titolo" value={this.state.titolo} type="text" placeholder="Titolo della sezione" required />
        </FormItem>
        <FormItem>
          <Row gutter={16}>
            <Col xs={24} sm={12}><Button type="primary" onClick={this.back}>Chiudi</Button></Col>
            <Col xs={24} sm={12}><Button type="primary" htmlType="submit">Salva</Button></Col>
          </Row>
        </FormItem>
      </Form>
      {lista_sezioni}
    </div>
    );
  }
}

class GetScenari extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      azione:'list',
      record:false,
      dataSource: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_scenari', id: this.props.sid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        for (let ii=0; ii<response.data.length; ii++) response.data[ii].key = ii;
        this.setState({ dataSource: response.data, count: response.data.length, azione: 'list' });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onDelete = (key,id) => {
    const dataSource = [...this.state.dataSource];
    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'del_scenario', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onEdit = (record) => {
    this.setState({azione:'edit', record:record});
  }

  onNew = () => {
    this.setState({azione:'new'});
  }

  doBack = (back) => {
    this.getData();
  }

  render() {

    if (debug) console.log('GetScenari - render')

    const { dataSource } = this.state;

    if (dataSource) {
      let html = [];
      let nuovo = <a onClick={this.onNew}><Icon type="plus-circle-o" /></a>

      if (this.state.azione==='list')
        html =
          <Table bordered size={'small'} pagination={false} dataSource={dataSource}>
            <Column title="Titolo" key="titolo"
              render={(text, record) => (record.titolo)} />)}
            />
            <Column title="" key="edit" width="5%"
              render={(text, record) => (
                <a onClick={() => this.onEdit(record)}><Icon type="edit" /></a>
              )}
            />
            <Column title={nuovo} key="delete" width="5%"
              render={(text, record) => (
                <Popconfirm title="Confermi?" onConfirm={() => this.onDelete(record.key,record.id)}>
                  <a href="#"><Icon type="delete" /></a>
                </Popconfirm>
              )}
            />
          </Table>

      if (this.state.azione==='new')
        html = <EditScenario sid={this.props.sid} data={null} back={this.doBack} />

      if (this.state.azione==='edit')
        html = <EditScenario data={this.state.record} back={this.doBack} />

      return (
        <React.Fragment>{html}</React.Fragment>
      );
    }
    else { return (<Icon type="loading" style={{ color: '#d6a54b', fontSize: 48 }} />) }
  }
}

class EditScenario extends React.Component {

  constructor(props) {
    super(props);

    if (this.props.data===null) {
      this.state = {
        id: 0,
        risposte: [],
        unanimita: 0,
        is_domanda: 0,
        testo: '',
        titolo: '',
      }
    } else {

      this.state = {
        risposte: [],
        unanimita: -1,
        is_domanda: -1,
        id: this.props.data.id,
        testo: this.props.data.testo,
        titolo: this.props.data.titolo,
      };

      this.getRisposte(this.props.data.id);

    }
  }

  componentDidMount() {
  }

  onChangeTitolo = (e) => {
    this.setState({titolo: e.target.value});
  }

  onChangeIsDomanda = (checked) => {
    this.setState({ is_domanda: (checked===true?1:0) });
  }

  onChangeUnanimita = (checked) => {
    this.setState({ unanimita: (checked===true?1:0) });
  }

  onEditorChange = (testo) => {
    this.setState({ testo:testo });
  };

  back = () => {
    this.props.back(true);
  }

  save = () => {

    let azione = (this.state.id===0) ? 'new_scenario' : 'upd_scenario';

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:azione, id: this.state.id, sid: this.props.sid, is_domanda: this.state.is_domanda, unanimita:this.state.unanimita, titolo:this.state.titolo, testo: this.state.testo}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        if (this.state.id===0) this.setState({id: response.data});
        warning("Dati salvati correttamente.");
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  getRisposte = (id) => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_risposte_domanda', sid:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        for (let ii=0; ii<response.data.length; ii++) response.data[ii].key = ii;
        this.setState({ risposte: response.data });
        this.setState({ unanimita: parseInt(this.props.data.unanimita,10) });
        this.setState({ is_domanda: parseInt(this.props.data.is_domanda,10) });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  /*
  uploadImageCallBack = (file) => {
    return new Promise(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', 'php/upload.php');
        const data = new FormData();
        //data.append('id', this.state.id);
        data.append('file', file);
        xhr.send(data);
        xhr.addEventListener('load', () => {
          const response = { data: JSON.parse(xhr.response)};
          resolve(response);
        });
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.response);
          reject(error);
        });
      }
    );
  }
  */

  render() {

    if (debug) console.log('EditScenario - render')

    let risposte = <div></div>
    let domanda = <div></div>
    let unanimita = <div></div>

    if (this.state.id===0)
      domanda =
      <div className="spazia">
        È una domanda? <Switch disabled /> (potrai impostare le risposte dopo aver salvato lo scenario)
      </div>

    if (this.state.id!==0 && this.state.is_domanda!==-1)
      domanda =
        <div className="spazia">
          È una domanda? <Switch defaultChecked={this.state.is_domanda===1?true:false} onChange={(checked) => this.onChangeIsDomanda(checked)} />
        </div>

    if (this.state.is_domanda===1)
      unanimita =
        <div className="spazia">
          Vuoi la votazione unanime? <Switch defaultChecked={this.state.unanimita===1?true:false} onChange={(checked) => this.onChangeUnanimita(checked)} />
        </div>


    if ((this.state.id!==0) && (this.state.is_domanda===1)) risposte = <GetRisposte data={this.state.risposte} sid={this.state.id} />

    //let xxx = ClassicEditor.builtinPlugins.map( plugin => (plugin.pluginName+' - ') );

    return (
      <div className="left">

        <p><Input onChange={this.onChangeTitolo} name="titolo" value={this.state.titolo} type="text" placeholder="Titolo dello scenario" required /></p>

        <CKEditor
          editor={ ClassicEditor }
          data={ this.state.testo }
          onInit={ editor => {
              // You can store the "editor" and use when it is needed.
              //console.log( 'Editor is ready to use!', editor );
              //console.log(Array.from( editor.ui.componentFactory.names() ))
          } }
          onChange={ ( event, editor ) => {
              this.onEditorChange(editor.getData())
          } }
        />

        {domanda}
        {unanimita}
        {risposte}

        <Row gutter={16}>
          <Col xs={24} sm={12}><Button type="primary" onClick={this.back}>Chiudi</Button></Col>
          <Col xs={24} sm={12}><Button type="primary" onClick={this.save}>Salva</Button></Col>
        </Row>

      </div>
    );
  }
}

class GetRisposte extends React.Component {

  constructor(props) {
    super(props);

    this.columns = [{
      title: 'Risposte',
      dataIndex: 'risposta',
      render: (text, record) => (
        <EditCella value={text} onChange={this.onUpdate(record.key, record.id)} />
      ),
    }, {
      title: <a onClick={this.onNew}><Icon type="plus-circle-o" /></a>,
      dataIndex: 'operation',
      width: '5%',
      render: (text, record) => {
        return (
          <Popconfirm title="Confermi?" onConfirm={() => this.onDelete(record.key, record.id)}>
            <a href="#"><Icon type="delete" /></a>
          </Popconfirm>
        );
      },
    }];

    this.state = {
      dataSource: this.props.data,
      count: this.props.data.length
    };

  }

  onUpdate = (key, id) => {
    return (value) => {
      const dataSource = [...this.state.dataSource];
      const target = dataSource.find(item => item.key === key);
      if (target) {
        axios({
          method:'post',
          url:'php/progetti.php',
          data: qs.stringify({azione:'upd_risposta_domanda', risposta:value, id:id}),
          responseType:'text'
        })
        .then(function(response) {
          if (response.data!=='') {
            target['risposta'] = value;
            this.setState({ dataSource });
          } else {
            warning('Ops, qualcosa è andato storto...')
          }
        }.bind(this))
        .catch(function (error) {
          warning('Ops, qualcosa è andato storto...',error.message);
          if (debug) console.log(error);
        });

      }
    };
  }

  onDelete = (key, id) => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'del_risposta_domanda', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  onNew = () => {

    const { count, dataSource } = this.state;
    const risposta = `Risposta ${count}`;

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'new_risposta_domanda', sid:this.props.sid, risposta:risposta}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        const newData = {
          id: response.data,
          key: count,
          risposta: risposta,
        };
        this.setState({
          dataSource: [...dataSource, newData],
          count: count + 1,
        });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  render() {

    if (debug) console.log('GetRisposte - render')

    const dataSource = [...this.state.dataSource];

    return (
      <div className="spazia">
        <Table bordered size={'small'} pagination={false} dataSource={dataSource} columns={this.columns} />
      </div>
    );
  }
}

class GetScenariClickme extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      azione:'list',
      record:false,
      dataSource: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_scenari', id: this.props.cid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        for (let ii=0; ii<response.data.length; ii++) response.data[ii].key = ii;
        this.setState({ dataSource: response.data, count: response.data.length, azione: 'list' });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onDelete = (key,id) => {
    const dataSource = [...this.state.dataSource];
    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'del_scenario', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onEdit = (record) => {
    this.setState({azione:'edit', record:record});
  }

  onNew = () => {
    this.setState({azione:'new'});
  }

  doBack = (back) => {
    this.getData();
  }

  render() {

    if (debug) console.log('GetScenariClickme - render')

    const { dataSource } = this.state;

    if (dataSource) {
      let html = [];
      let nuovo = <a onClick={this.onNew}><Icon type="plus-circle-o" /></a>

      if (this.state.azione==='list')
        html =
          <Table bordered size={'small'} pagination={false} dataSource={dataSource}>
            <Column title="Nome" key="nome"
              render={(text, record) => (record.nome)} />)}
            />
            <Column title="" key="edit" width="5%"
              render={(text, record) => (
                <a onClick={() => this.onEdit(record)}><Icon type="edit" /></a>
              )}
            />
            <Column title={nuovo} key="delete" width="5%"
              render={(text, record) => (
                <Popconfirm title="Confermi?" onConfirm={() => this.onDelete(record.key,record.id)}>
                  <a href="#"><Icon type="delete" /></a>
                </Popconfirm>
              )}
            />
          </Table>

      if (this.state.azione==='new')
        html = <EditScenarioClickme data={null} cid={this.props.cid} back={this.doBack} />

      if (this.state.azione==='edit')
        html = <EditScenarioClickme data={this.state.record} cid={this.props.cid} back={this.doBack} />

      return (
        <React.Fragment>{html}</React.Fragment>
      );
    }
    else { return (<Icon type="loading" style={{ color: '#d6a54b', fontSize: 48 }} />) }
  }
}

class EditScenarioClickme extends React.Component {

  constructor(props) {
    super(props);
    if (this.props.data===null) {
      this.state = {
        id: 0,
        risposte: [],
        nome: '',
      }
    } else {
      this.state = {
        risposte: [],
        id: this.props.data.id,
        nome: this.props.data.nome,
      };
    }
  }

  onChangeNome = (e) => {
    this.setState({nome: e.target.value});
  }

  back = () => {
    this.props.back(true);
  }

  save = () => {

    let azione = (this.state.id===0) ? 'new_scenario' : 'upd_scenario';

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:azione, id:this.state.id, cid:this.props.cid, nome:this.state.nome}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        if (this.state.id===0) this.setState({id: response.data});
        warning("Dati salvati correttamente.");
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  render() {

    if (debug) console.log('EditScenarioClickme - render')

    let parametri = '';
    if (this.state.id!==0)
      parametri =
        <div className="box-editor">
          <h3>Parametri scenario Click Me</h3>
          <GetParametriClickme sid={this.state.id} />
        </div>

    return (
      <div>
        <p><Input onChange={this.onChangeNome} name="titolo" value={this.state.nome} type="text" placeholder="Nome dello scenario" required /></p>
        <Row gutter={16}>
          <Col xs={24} sm={12}><Button type="primary" onClick={this.back}>Chiudi</Button></Col>
          <Col xs={24} sm={12}><Button type="primary" onClick={this.save}>Salva</Button></Col>
        </Row>
        {parametri}
      </div>
    );
  }
}

class GetParametriClickme extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      azione:'list',
      record:false,
      dataSource: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_parametri', id: this.props.sid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        for (let ii=0; ii<response.data.length; ii++) response.data[ii].key = ii;
        this.setState({ dataSource: response.data, count: response.data.length, azione: 'list' });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onDelete = (key,id) => {
    const dataSource = [...this.state.dataSource];
    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'del_parametro', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onEdit = (record) => {
    this.setState({azione:'edit', record:record});
  }

  onNew = () => {
    this.setState({azione:'new'});
  }

  doBack = (back) => {
    this.getData();
  }

  render() {

    if (debug) console.log('GetParametriClickme - render')

    const { dataSource } = this.state;

    if (dataSource) {
      let html = [];
      let nuovo = <a onClick={this.onNew}><Icon type="plus-circle-o" /></a>

      if (this.state.azione==='list')
        html =
          <Table bordered size={'small'} pagination={false} dataSource={dataSource}>
            <Column title="Nome" key="nome"
              render={(text, record) => (record.nome)} />)}
            />
            <Column title="" key="edit" width="5%"
              render={(text, record) => (
                <a onClick={() => this.onEdit(record)}><Icon type="edit" /></a>
              )}
            />
            <Column title={nuovo} key="delete" width="5%"
              render={(text, record) => (
                <Popconfirm title="Confermi?" onConfirm={() => this.onDelete(record.key,record.id)}>
                  <a href="#"><Icon type="delete" /></a>
                </Popconfirm>
              )}
            />
          </Table>

      if (this.state.azione==='new')
        html = <EditParametroClickme data={null} sid={this.props.sid} back={this.doBack} />

      if (this.state.azione==='edit')
        html = <EditParametroClickme data={this.state.record} sid={this.props.sid} back={this.doBack} />

      return (
        <React.Fragment>{html}</React.Fragment>
      );
    }
    else { return (<Icon type="loading" style={{ color: '#d6a54b', fontSize: 48 }} />) }
  }
}

class EditParametroClickme extends React.Component {

  constructor(props) {
    super(props);
    if (this.props.data===null) {
      this.state = {
        id: 0,
        opzioni: false,
        nome: '',
      }
    } else {
      this.state = {
        opzioni: false,
        id: this.props.data.id,
        nome: this.props.data.nome,
      };

      this.getOpzioni(this.props.data.id);

    }
  }

  onChangeNome = (e) => {
    this.setState({nome: e.target.value});
  }

  back = () => {
    this.props.back(true);
  }

  save = () => {

    let azione = (this.state.id===0) ? 'new_parametro' : 'upd_parametro';

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:azione, id:this.state.id, sid:this.props.sid, nome:this.state.nome}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        if (this.state.id===0) this.setState({id:response.data, opzioni:[]});
        warning("Dati salvati correttamente.");
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  getOpzioni = (id) => {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_opzioni', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        for (let ii=0; ii<response.data.length; ii++) response.data[ii].key = ii;
        this.setState({ opzioni: response.data });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  render() {

    if (debug) console.log('EditParametroClickme - render')

    let opzioni = '';
    if (this.state.id!==0 && this.state.opzioni)
      opzioni =
        <div className="box-editor">
          <h3>Opzioni parametro scenario Click Me</h3>
          <GetOpzioniClickme data={this.state.opzioni} pid={this.state.id} />
        </div>

    return (
      <div>
        <p><Input onChange={this.onChangeNome} name="titolo" value={this.state.nome} type="text" placeholder="Nome del parametro" required /></p>
        <Row gutter={16}>
          <Col xs={24} sm={12}><Button type="primary" onClick={this.back}>Chiudi</Button></Col>
          <Col xs={24} sm={12}><Button type="primary" onClick={this.save}>Salva</Button></Col>
        </Row>
        {opzioni}
      </div>
    );
  }
}

class GetOpzioniClickme extends React.Component {

  constructor(props) {
    super(props);

    this.columns = [{
      title: 'Opzioni',
      dataIndex: 'nome',
      render: (text, record) => (
        <EditCella value={text} onChange={this.onUpdate(record.key, record.id)} />
      ),
    }, {
      title: <a onClick={this.onNew}><Icon type="plus-circle-o" /></a>,
      dataIndex: 'operation',
      width: '5%',
      render: (text, record) => {
        return (
          <Popconfirm title="Confermi?" onConfirm={() => this.onDelete(record.key, record.id)}>
            <a href="#"><Icon type="delete" /></a>
          </Popconfirm>
        );
      },
    }];

    this.state = {
      dataSource: this.props.data,
      count: this.props.data.length
    };

  }

  onUpdate = (key, id) => {
    return (value) => {
      const dataSource = [...this.state.dataSource];
      const target = dataSource.find(item => item.key === key);
      if (target) {
        axios({
          method:'post',
          url:'php/clickme.php',
          data: qs.stringify({azione:'upd_opzione', nome:value, id:id}),
          responseType:'text'
        })
        .then(function(response) {
          if (response.data!=='') {
            target['nome'] = value;
            this.setState({ dataSource });
          } else {
            warning('Ops, qualcosa è andato storto...')
          }
        }.bind(this))
        .catch(function (error) {
          warning('Ops, qualcosa è andato storto...',error.message);
          if (debug) console.log(error);
        });

      }
    };
  }

  onDelete = (key, id) => {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'del_opzione', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  onNew = () => {

    const { count, dataSource } = this.state;
    const nome = `Opzione ${count}`;

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'new_opzione', pid:this.props.pid, nome:nome}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        const newData = {
          id: response.data,
          key: count,
          nome: nome,
        };
        this.setState({
          dataSource: [...dataSource, newData],
          count: count + 1,
        });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  render() {

    if (debug) console.log('GetOpzioniClickme - render')

    const dataSource = [...this.state.dataSource];

    return (
      <div className="spazia">
        <Table bordered size={'small'} pagination={false} dataSource={dataSource} columns={this.columns} />
      </div>
    );
  }
}

class GetTerapieClickme extends React.Component {

  constructor(props) {
    super(props);

    this.columns = [{
      title: 'Nome',
      dataIndex: 'nome',
      render: (text, record) => (
        <EditCella value={text} onChange={this.onUpdate(record.key, record.id)} />
      ),
    }, {
      title: <a onClick={this.onNew}><Icon type="plus-circle-o" /></a>,
      dataIndex: 'operation',
      width: '5%',
      render: (text, record) => {
        return (
          <Popconfirm title="Confermi?" onConfirm={() => this.onDelete(record.key, record.id)}>
            <a href="#"><Icon type="delete" /></a>
          </Popconfirm>
        );
      },
    }];

    this.state = {
      dataSource: false,
    };
  }

  componentDidMount () {
    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_terapie', cid:this.props.cid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        this.setState({ dataSource:response.data, count:response.data.length });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onUpdate = (key, id) => {
    return (value) => {
      const dataSource = [...this.state.dataSource];
      const target = dataSource.find(item => item.key === key);
      if (target) {
        axios({
          method:'post',
          url:'php/clickme.php',
          data: qs.stringify({azione:'upd_terapia', nome:value, id:id}),
          responseType:'text'
        })
        .then(function(response) {
          if (response.data!=='') {
            target['nome'] = value;
            this.setState({ dataSource });
          } else {
            warning('Ops, qualcosa è andato storto...')
          }
        }.bind(this))
        .catch(function (error) {
          warning('Ops, qualcosa è andato storto...',error.message);
          if (debug) console.log(error);
        });
      }
    };
  }

  onDelete = (key, id) => {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'del_terapia', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  onNew = () => {

    const { count, dataSource } = this.state;
    const nome = `Trattamento ${count}`;

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'new_terapia', cid:this.props.cid, nome:nome}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        const newData = {
          id: response.data,
          key: count,
          nome: nome,
        };
        this.setState({
          dataSource: [...dataSource, newData],
          count: count + 1,
        });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  render() {

    if (debug) console.log('GetTerapieClickme - render')

    const dataSource = [...this.state.dataSource];

    if (dataSource)
      return (
        <div className="spazia">
          <Table bordered size={'small'} pagination={false} dataSource={dataSource} columns={this.columns} />
        </div>
      );
    else
      return (null);
  }
}

class EditCella extends React.Component {

  state = {
    value: this.props.value,
    editable: false,
  }
  handleChange = (e) => {
    const value = e.target.value;
    this.setState({ value });
  }
  check = () => {
    this.setState({ editable: false });
    if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
  }
  edit = () => {
    this.setState({ editable: true });
  }
  render() {
    const { value, editable } = this.state;
    return (
      <div className="editable-cell">
        {
          editable ?
            <div className="editable-cell-input-wrapper">
              <Input
                value={value}
                onChange={this.handleChange}
                onPressEnter={this.check}
              />
              <Icon
                type="check"
                className="editable-cell-icon-check"
                onClick={this.check}
              />
            </div>
            :
            <div className="editable-cell-text-wrapper">
              {value || ' '}
              <Icon
                type="edit"
                className="editable-cell-icon"
                onClick={this.edit}
              />
            </div>
        }
      </div>
    );
  }
}

class GetStats extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      chart:false,
    };

  }

  componentDidMount() {
    this.doChart();
  }

  chartToImage = () => {

    let png_chart = this.state.chart.toBase64Image();
    window.open(png_chart,"_blank");
  }

  doChart = () => {

    let labels = [];
    let values = [];
    let bcolor = [];
    let pie_colors = ['#009ee2','#ffcd56','#83b42d','#a72035','#005b6c','#f26e21','#4bc0c0','#ff6384','#ff9f40'];

    let totale = 0;
    for (let ii=0; ii<this.props.data.length; ii++) totale = totale + parseInt(this.props.data[ii].tot,10);

    for (let ii=0; ii<this.props.data.length; ii++) {
      let percentuale = Math.round(100/totale*parseInt(this.props.data[ii].tot,10));
      if (isNaN(percentuale)) percentuale = 0;
      labels[ii] = this.props.data[ii].risposta+' ('+percentuale+'%)';
      values[ii] = parseInt(this.props.data[ii].tot,10);
      if (this.props.chart_type==='bar') bcolor[ii] = '#d6a54b';
      if (this.props.chart_type==='pie') bcolor[ii] = pie_colors[ii];
      //if (this.props.chart_type==='pie') bcolor[ii] = 'rgba(214, 165, 75, '+((ii+1)/10)+')';
    }

    let data = {
      labels: labels,
      datasets: [
        {
          label: 'numero di voti',
          data: values,
          backgroundColor: bcolor,
          borderColor: bcolor,
          borderWidth: 1
        },
      ]
    }
    let options = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero:true,
            stepSize:1,
          }
        }]
      }
    }

    let chart = new Chart(document.getElementById("myChart"+this.props.data[0].id), {
                type: this.props.chart_type,
                data: data,
                options: options
    });

    this.setState({chart:chart});

  }

  render() {

    if (debug) console.log('GetStats')

    return (
      <div className="chart">
        <h3>{this.props.data[0].caso} - {this.props.data[0].sezione} - {this.props.data[0].scenario}</h3>
        <p><a onClick={this.chartToImage}>scarica in formato immagine</a></p>
        <canvas id={"myChart"+this.props.data[0].id} width="4" height="2"></canvas>
      </div>
    );

  }
}

class GetTrackingClickme extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      html:[]
    };

  }

  componentDidMount() {
    this.showData();
  }

  showData = () => {

    let html = [];
    let casi = [];
    let cid = 0;

    for (let ii=0; ii<this.props.data.length; ii++) {
      if (this.props.data[ii].cid!==cid) {
        cid = this.props.data[ii].cid;
        let panel_utente = [];
        for (let jj=0;jj<this.props.data.length; jj++) {
          if (this.props.data[jj].cid===cid) {
            let trattamento = '';
            if (this.props.data[jj].terapia!==null) trattamento = <p><strong>Trattamento scelto:</strong> {this.props.data[jj].terapia}</p>
            let scenario =
              <div>
                <GetScenariUtenteClickme cid={cid} opzioni={this.props.data[jj].opzioni} />
                {trattamento}
              </div>

            panel_utente[jj] = <Panel key={jj} className="panel" header={this.props.data[jj].nome+' '+this.props.data[jj].cognome}>{scenario}</Panel>
          }
        }
        let utenti = <Collapse accordion bordered={false}>{panel_utente}</Collapse>
        casi[ii] = <Panel key={ii} className="panel" header={this.props.data[ii].caso+' ('+this.props.data[ii].autore+')'}>{utenti}</Panel>
      }
    }

    html = <Collapse accordion bordered={false}>{casi}</Collapse>

    this.setState({html:html});
  }

  render() {

    if (debug) console.log('GetTrackingClickme')

    return (
      <div className="left">
        {this.state.html}
      </div>
    );

  }
}

class GetScenariUtenteClickme extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      scenari:false
    };

  }

  componentDidMount() {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_scenari', id:this.props.cid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        this.setState({ scenari: response.data});
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  render() {

    if (debug) console.log('GetScenarioUtenteClickme')

    let html = [];
    if (this.state.scenari) {
      for (let ii=0; ii<this.state.scenari.length; ii++) {
        html[ii] =
          <Row key={ii}>
            <p><strong>{this.state.scenari[ii].nome}</strong></p>
            <GetParametriUtenteClickme id={ii} sid={this.state.scenari[ii].id} opzioni={this.props.opzioni} />
          </Row>;
      }
    }
    return (
      <div>
        {html}
      </div>
    );

  }
}

class GetParametriUtenteClickme extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      parametri:false
    };

  }

  componentDidMount() {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_parametri', id:this.props.sid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        this.setState({ parametri: response.data});
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  render() {

    if (debug) console.log('GetParametroUtenteClickme')

    let html = [];
    if (this.state.parametri) {
      for (let ii=0; ii<this.state.parametri.length; ii++) {
        html[ii] =
          <Row key={ii}>
            <Col sx={12} md={ 4}>{this.state.parametri[ii].nome}:</Col>
            <Col sx={12} md={20}><GetOpzioniUtenteClickme pid={this.state.parametri[ii].id} opzioni={this.props.opzioni} /></Col>
          </Row>;
      }
    }
    return (
      <div>
        {html}
      </div>
    );

  }
}

class GetOpzioniUtenteClickme extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      opzione:false
    };

  }

  componentDidMount() {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_opzioni', id:this.props.pid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        let nome_opz = '';
        let data_opz = response.data;
        let opzioni = ','+this.props.opzioni+',';
        for (let ii=0; ii<data_opz.length; ii++) if (opzioni.includes(','+data_opz[ii].id+',')) nome_opz = data_opz[ii].nome
        this.setState({opzione:nome_opz});
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  render() {

    if (debug) console.log('GetParametroUtenteClickme')

    if (this.state.opzione)
      return (<React.Fragment>{this.state.opzione}</React.Fragment>);
    else
      return (null);

  }
}

export default EditProgetti;
