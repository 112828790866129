import React from 'react';
import { Row, Col, Table, Form, Input, Select, Icon, Button, Popconfirm } from 'antd';
import axios from 'axios';
import { debug, warning } from './Utilities'

const { Column } = Table;
const FormItem = Form.Item;
const Option = Select.Option;

var qs = require('qs');

class EditUtenti extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      azione:'list',
      record:false,
      dataSource: false,
      count: 0,
      filterDropdownVisible: false,
      searchText: '',
      filtered: false,
    };

  }

  componentDidMount() {

    if (debug) console.log('EditUtenti - componentDidMount')
    this.getData();
  }

  getData = () => {

    this.setState({filterDropdownVisible:false, filtered:false, searchText:''});

    axios({
      method:'post',
      url:'php/utenti.php',
      data: qs.stringify({azione:'get'}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='') {
        for (let ii=0; ii<response.data.length; ii++) response.data[ii].key = ii;
        this.setState({ dataSource: response.data, count: response.data.length, azione: 'list' });
      } else {
        warning('Ops, qualcosa è andato storto...')
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  onDelete = (key,id) => {

    const dataSource = [...this.state.dataSource];

    if (debug) console.log(qs.stringify({azione:'del', id:id}))

    axios({
      method:'post',
      url:'php/utenti.php',
      data: qs.stringify({azione:'del', id:id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onEdit = (record) => {

    this.setState({azione:'edit', record:record});

  }

  onNew = () => {
    this.setState({azione:'new'});
  }

  doBack = (back) => {
    this.getData();
  }

  onInputChange = (e) => {
    this.setState({ searchText: e.target.value });
  }

  onSearch = () => {
    const { dataSource } = this.state;
    const { searchText } = this.state;
    const reg = new RegExp(searchText, 'gi');
    this.setState({
      filterDropdownVisible: false,
      filtered: !!searchText,
      dataSource: dataSource.map((record) => {
        const match = record.cognome.match(reg);
        if (!match) {
          return null;
        }
        return {
          ...record,
        };
      }).filter(record => !!record),
    });
  }

  render() {

    if (debug) console.log('EditUtenti - render')

    const { dataSource } = this.state;

    if (dataSource) {

      let html = [];
      let nuovo = <a onClick={this.onNew}><Icon type="plus-circle-o" /></a>
      let colonna_new_del = '';
      if (this.props.ruolo==='admin')
        colonna_new_del =
          <Column title={nuovo} key="delete" width="5%"
            render={(text, record) => (
              record.id==='1' ? null :
              <Popconfirm title="Confermi?" onConfirm={() => this.onDelete(record.key,record.id)}>
                <a><Icon type="delete" /></a>
              </Popconfirm>
            )}
          />

      if (this.state.azione==='list')
        html =
          <Table bordered pagination={true} dataSource={dataSource}>
            <Column title="Nome" dataIndex="nome" key="nome" defaultSortOrder="ascend"
                    sorter={(a, b) => a.nome.localeCompare(b.nome)}
            />
            <Column title="Cognome" dataIndex="cognome" key="cognome" defaultSortOrder="ascend"
                    sorter={(a, b) => a.cognome.localeCompare(b.cognome)}
                    filterDropdownVisible={this.state.filterDropdownVisible}
                    filterDropdown={(
                      <div className="custom-filter-dropdown">
                        <Input
                          ref={ele => this.searchInput = ele}
                          value={this.state.searchText}
                          onChange={this.onInputChange}
                          onPressEnter={this.onSearch}
                        />
                        <Button type="primary" onClick={this.onSearch}>Cerca</Button>
                        <Button type="primary" onClick={this.getData}>Reset</Button>
                      </div>
                    )}
                    onFilterDropdownVisibleChange={(visible) => {
                      this.setState({
                        filterDropdownVisible: visible,
                      }, () => this.searchInput && this.searchInput.focus());
                    }}
            />
            <Column title="Username" dataIndex="username" key="username" defaultSortOrder="ascend"
                    sorter={(a, b) => a.username.localeCompare(b.username)}
            />
            <Column title="email" dataIndex="email" key="email" />
            <Column title="" key="edit" width="5%"
              render={(text, record) => (
                <a onClick={() => this.onEdit(record)}><Icon type="edit" /></a>
              )}
            />
            {colonna_new_del}
          </Table>

      if (this.state.azione==='new')
        html = <EditUtente data={null} ruolo={this.props.ruolo} back={this.doBack} />

      if (this.state.azione==='edit')
        html = <EditUtente data={this.state.record} ruolo={this.props.ruolo} back={this.doBack} />

      return (
        <React.Fragment>{html}</React.Fragment>
      );
    }
    else { return (<Icon type="loading" style={{ color: '#d6a54b', fontSize: 48 }} />) }
  }
}

class EditUtente extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      ruolo:'user',
      progetti: false,
      progetti_utente: false,
      id_progetti: [],
      old_progetti: [],
    };

    if (this.props.data!=null)
      this.state = {
        progetti: false,
        progetti_utente: false,
        id_progetti: [],
        old_progetti: [],
        id: this.props.data.id,
        nome: this.props.data.nome,
        cognome: this.props.data.cognome,
        email: this.props.data.email,
        username: this.props.data.username,
        ruolo: this.props.data.ruolo,
        riferimento: this.props.data.riferimento,
        password: ''
      };

  }

  componentDidMount() {

    if (debug) console.log('EditUtente - componentDidMount')

      // lista dei progetti disponibili
      axios({
        method:'post',
        url:'php/progetti.php',
        data: qs.stringify({azione:'get'}),
        responseType:'text'
      })
      .then(function(response1) {
        if (this.state.id!==0) { //lista progetti a cui l'utente è iscritto
          axios({
            method:'post',
            url:'php/utenti.php',
            data: qs.stringify({azione:'get_progetti_by_user', uid: this.state.id}),
            responseType:'text'
          })
          .then(function(response2) {
            let id_progetti = [];
            for (let ii=0; ii<response2.data.length; ii++) id_progetti[ii] = response2.data[ii].id;
            this.setState({ progetti: response1.data, progetti_utente: response2.data, id_progetti: id_progetti, old_progetti: id_progetti});
          }.bind(this))
          .catch(function (error) {
            warning('Ops, qualcosa è andato storto...',error.message);
            if (debug) console.log(error);
          });
        } else {
          this.setState({ progetti: response1.data });
        }

      }.bind(this))
      .catch(function (error) {
        warning('Ops, qualcosa è andato storto...',error.message);
        if (debug) console.log(error);
      });


  }

  cancel = () => {
    this.props.back(true);
  }

  onChangeField = (e) => {
    if (e.target.name==='nome') this.setState({nome: e.target.value});
    if (e.target.name==='cognome') this.setState({cognome: e.target.value});
    if (e.target.name==='email') this.setState({email: e.target.value});
    if (e.target.name==='username') this.setState({username: e.target.value});
    if (e.target.name==='ruolo') this.setState({ruolo: e.target.value});
    if (e.target.name==='password') this.setState({password: e.target.value});
  }

  selRuolo = (value) => { this.setState({ruolo:value}); }
  selProgetto = (value) => { this.setState({id_progetti:value}); }

  save = (e) => {

    e.preventDefault();

    let azione = (this.state.id===0) ? 'new' : 'upd';
    let new_progetti = (this.state.id_progetti.length!==0) ? this.state.id_progetti.join(',') : 0;
    let old_progetti = (this.state.old_progetti.length!==0) ? this.state.old_progetti.join(',') : 0;

    if (debug) console.log('old_progetti='+old_progetti)
    if (debug) console.log('new_progetti='+new_progetti)

    axios({
      method:'post',
      url:'php/utenti.php',
      data: qs.stringify({azione:azione, id: this.state.id, old_progetti:old_progetti, new_progetti: new_progetti, nome: this.state.nome, cognome: this.state.cognome, email: this.state.email, ruolo: this.state.ruolo, riferimento: this.state.riferimento, username: this.state.username, password: this.state.password}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...');
      } else {
        if (response.data==='duplicate') {
          warning('Dati NON salvati, username già utilizzato!');
        } else {
          this.setState({id: response.data, old_progetti: this.state.id_progetti});
          warning("Dati salvati correttamente.");
        }
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  render() {

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 22 },
      },
    };

    let progetti = '';

    if (this.state.progetti && this.state.ruolo!=='admin') { //No progetti per gli admin
      let options = [];
      let values = [];
      for (let ii=0; ii<this.state.progetti_utente.length; ii++) values[ii] = this.state.progetti_utente[ii].id;
      for (let ii=0; ii<this.state.progetti.length; ii++) options[ii] = <Option key={ii} value={this.state.progetti[ii].id}>{this.state.progetti[ii].titolo}</Option>
      progetti =
        <FormItem {...formItemLayout} label="Progetti:">
          <Select mode="multiple" style={{ width: '100%' }} defaultValue={values} onChange={this.selProgetto}>
            {options}
          </Select>
        </FormItem>
    }

    return (
    <div>
      <Form id="the-form" onSubmit={this.save}>
        <FormItem {...formItemLayout} label="Ruolo:">
          <Select defaultValue={this.state.ruolo} onChange={this.selRuolo}>
            <Option key='1' value='user'>user</Option>
            <Option key='2' value='tutor'>tutor</Option>
            <Option key='3' value='admin'>admin</Option>
          </Select>
        </FormItem>
        <FormItem {...formItemLayout} label="Nome:">
          <Input onChange={this.onChangeField} name="nome" value={this.state.nome} type="text" required />
        </FormItem>
        <FormItem {...formItemLayout} label="Cognome:">
          <Input onChange={this.onChangeField} name="cognome" value={this.state.cognome} type="text" required />
        </FormItem>
        <FormItem {...formItemLayout} label="Email:">
          <Input onChange={this.onChangeField} name="email" value={this.state.email} type="text" required />
        </FormItem>
        <FormItem {...formItemLayout} label="Username:">
          <Input onChange={this.onChangeField} name="username" value={this.state.username} type="text" required />
        </FormItem>
        <FormItem {...formItemLayout} label="Password:">
          <Input onChange={this.onChangeField} name="password" value={this.state.password} type="text" />
        </FormItem>
        {progetti}
        <FormItem>
          <Row gutter={16}>
            <Col xs={24} sm={8}><Button type="primary" onClick={this.cancel}>Annulla</Button></Col>
            <Col xs={24} sm={8}><Button type="primary" htmlType="submit">Salva</Button></Col>
            <Col xs={24} sm={8}><Button type="primary" onClick={this.cancel}>Chiudi</Button></Col>
          </Row>
        </FormItem>
      </Form>
    </div>
    );
  }
}

export default EditUtenti;
