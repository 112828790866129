import React from 'react';
import { Button, Row, Col } from 'antd';
import { debug } from './Utilities'
import EditUtenti from './EditUtenti';
import EditProgetti from './EditProgetti';
import './Admin.css';

class AdminContainer extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      azione: false,
    };

  }

  getProgetti = () => {
    this.setState({azione: 'progetti'});
  }

  getUtenti = () => {
    this.setState({azione: 'utenti'});
  }

  render() {

    if(debug) console.log('AdminContainer');

    let listaUtenti = [];
    let listaProgetti = [];

    if (this.state.azione==='utenti') listaUtenti = <EditUtenti ruolo={this.props.ruolo} />
    if (this.state.azione==='progetti') listaProgetti = <EditProgetti uid={this.props.uid} ruolo={this.props.ruolo} />

    let bottoni =
      <Row gutter={16}>
        <Col xs={24} sm={12}><Button type="primary" onClick={this.getProgetti}>Gestisci progetti</Button></Col>
        <Col xs={24} sm={12}><Button type="primary" onClick={this.getUtenti}>Gestisci utenti</Button></Col>
      </Row>

    if (this.props.ruolo==='tutor')
      bottoni =
        <Row>
          <Col xs={24}><Button type="primary" onClick={this.getProgetti}>Gestisci progetti</Button></Col>
        </Row>

    return (
      <div>
        <p />
        {bottoni}
        {listaUtenti}
        {listaProgetti}
      </div>
    )
  }
}

export default AdminContainer;
