import React, { Component } from 'react';
//import { Modal } from 'antd';
//import ReactDOM from 'react-dom';
import axios from 'axios';

import logo from './logo.svg';
import {CheckIn} from './Auth.js';

import {PlayScenariSolo} from './User';
import {PlayProgettoSolo} from './User';

import { debug, warning } from './Utilities'
import './App.css';

import { ConfigProvider } from 'antd';
import it_IT from 'antd/lib/locale-provider/it_IT';
import 'moment/locale/it';

var qs = require('qs');

class App extends Component {

  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {

    let u = $_GET('u')
    let p = $_GET('p')
    let s = $_GET('s')
    let t = $_GET('t')

    if (u && p && s && t) {
      return (
        <ConfigProvider locale={it_IT}>
          <div className="App">
            <div className="layout">
              <div className="content">
                <Guest username={u} progetto={p} sezione={s} tipo={t} />
              </div>
              <div className="content">&nbsp;</div>
            </div>
          </div>
        </ConfigProvider>
      );
    }

    let nome = decodeURIComponent($_GET('nome'))
    let cognome = decodeURIComponent($_GET('cognome'))
    let username = decodeURIComponent($_GET('username'))
    let progetto = $_GET('progetto')

    if (progetto && username && nome && cognome) {
      return (
        <ConfigProvider locale={it_IT}>
          <div className="App">
            <div className="layout">
              <div className="header">
                <img src={logo} className="App-logo" alt="logo" />
              </div>
              <div className="content">
                <Moodle username={username} progetto={progetto} nome={nome} cognome={cognome} />
              </div>
              <div className="content">&nbsp;</div>
            </div>
          </div>
        </ConfigProvider>
      );
    }

    return (
      <ConfigProvider locale={it_IT}>
      <div>
        <div className="App">
          <div className="layout">
            <div className="header">
              <img src={logo} className="App-logo" alt="logo" />
            </div>
            <div className="content">
              <CheckIn />
            </div>
            <div className="content">&nbsp;</div>
          </div>
        </div>
        <div className="footer">
          © Accademia Nazionale di Medicina -  <a href="https://www.accmed.org/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a>
        </div>
      </div>
      </ConfigProvider>
    );
  }
}

class Moodle extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      uid: false
    };

    this.logIn();
  }

  logIn = () => {

    axios({
      method:'post',
      url:'php/login.php',
      data: qs.stringify({from:'moodle', progetto: this.props.progetto, username:this.props.username, nome: this.props.nome, cognome: this.props.cognome}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='')
        this.setState({uid:response.data.id});
      else
        warning('Ops, qualcosa è andato storto...')
    }.bind(this))
    .catch(function (error) {
      if (debug) console.log(error);
    });

  }

  render() {
    if (this.state.uid)
      return (<PlayProgettoSolo uid={this.state.uid} pid={this.props.progetto} />)
    else
      return null;
  }
}

class Guest extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      uid: false
    };

    this.logIn();
  }

  logIn = () => {

    axios({
      method:'post',
      url:'php/login.php',
      data: qs.stringify({ur: this.props.username, pw: this.props.username}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data!=='')
        this.setState({uid:response.data[0].id});
    }.bind(this))
    .catch(function (error) {
      if (debug) console.log(error);
    });

  }

  render() {
    if (this.state.uid)
      return (<PlayScenariSolo uid={this.state.uid} pid={this.props.progetto} sid={this.props.sezione} tipo={this.props.tipo} />)
    else
      return null;
  }
}

function $_GET(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] === variable){return pair[1];}
	}
	return false;
}

export default App;
