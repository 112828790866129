import React from 'react';
import { Button, Form, Radio, Table, Row, Col } from 'antd';
import {Logout} from './Auth.js';
import { debug, warning } from './Utilities'
import axios from 'axios';

var qs = require('qs');

var preview = false;

const { Column } = Table;
const RadioGroup = Radio.Group;
const polling_rate = 3000;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

function createMarkup(html) { return {__html: html} }

function setZoom() {
  //Vanilla js per jquery $('img').click()
  [].forEach.call(document.querySelectorAll('img'), function(el) {
    if (el.getAttribute('alt')!=='logo' && el.getAttribute('alt')!=='zoom') {
      el.setAttribute('class','zoom-in')
      el.addEventListener('click', function() {
        zoomIn(el.getAttribute('src'))
      })
    }
  })
}

function zoomIn(src) {
  document.getElementById('root').setAttribute("style", "display:none")
  document.getElementById('zoom').innerHTML = '<img onclick="zoomOut()" class="zoom-out" alt="zoom" src="'+src+'" width="100%" />'
}

class Preview extends React.Component {

  constructor(props) {
    super(props);

    preview = true;

    this.state = {
      data: false,
      refresh: false
    };
  }

  componentDidMount() {
    this.getProgetti();
    //if (this.props.guest) this.init();
  }

  getProgetti = () => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'preview', id: this.props.pid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({data: response.data});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  init = () => {

    this.setState({data: false});

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'init_preview', uid: this.props.uid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.getProgetti();
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }
  render() {

    if(debug) console.log('Preview');

    let casi = '';
    if (this.state.data) casi = <GetCasi titolo={this.state.data.titolo} uid={this.props.uid} pid={this.state.data.id} gid={'0'}  solo={true} />

    let btn_reset = <Button type="primary" className="spazia" ghost onClick={this.init}>Reset preview</Button>

    return (
      <div>
        {btn_reset}
        {casi}
      </div>
    )
  }
}

class PlayProgettoSolo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: false,
      //refresh: false
    };
  }

  componentDidMount() {
    this.getProgetto();
  }

  getProgetto = () => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'preview', id: this.props.pid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({data: response.data});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }
  /*
  init = () => {

    this.setState({data: false});

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'init_preview', uid: this.props.uid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.getProgetti();
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }
  */
  render() {

    if(debug) console.log('PlayProgettoSolo');

    let casi = '';
    if (this.state.data) casi = <GetCasi titolo={this.state.data.titolo} uid={this.props.uid} pid={this.state.data.id} gid={'0'} solo={true} />

    return (
      <div>
        {casi}
      </div>
    )
  }
}

class PlayScenariSolo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      refresh: false
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'init_preview', uid: this.props.uid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({refresh:true});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }
  render() {

    if(debug) console.log('PlayScenariSolo');

    if (this.state.refresh) {
      if (this.props.tipo==='default')
        return (
          <div><GetScenari titolo={''} solo={true} sid={this.props.sid} uid={this.props.uid} pid={this.props.pid} gid={'0'} /></div>
        )
      if (this.props.tipo==='clickme')
        return (
          <div><GetClickme titolo={''} solo={true} cid={this.props.sid} uid={this.props.uid} pid={this.props.pid} gid={'0'} /></div>
        )
    }
    else return null;
  }
}

class UserContainer extends React.Component {

  constructor(props) {
    super(props);

    preview = false;

    this.state = {
      data: false,
      step: 'start'
    };
  }

  componentDidMount() {
    this.getProgetti();
    this.interval = setInterval(() => this.getProgetti(), polling_rate);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getProgetti = () => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_by_user', id: this.props.uid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({data: response.data});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  startProgetto = (ii) => {
    this.setState({step: 'casi', progetto: ii});
    clearInterval(this.interval);
  }

  handleBack = () => {
    this.setState({step: 'start'});
  }

  handleLogRole = (ruolo) => {
    window.location.reload(); //brutto, pazienza
  }

  render() {

    if(debug) console.log('UserContainer');

    if (this.state.step==='start')

      if (this.state.data) {
        let html = [];
        for (let ii=0; ii<this.state.data.length; ii++)
          if (this.state.data[ii].stato==='0')
            html[ii] = <p key={ii}>{this.state.data[ii].titolo} (sessione chiusa)</p>
          else
            html[ii] = <p className="btn_progetto" key={ii}><a className="a_progetto" onClick={() => this.startProgetto(ii)}>{this.state.data[ii].titolo}</a></p>
/*
          if (this.state.data[ii].stato==='0')
            html[ii] = <p key={ii}><Button size={'large'} disabled>{this.state.data[ii].titolo} (sessione chiusa)</Button></p>
          else
            html[ii] = <p key={ii}><Button size={'large'} onClick={() => this.startProgetto(ii)}>{this.state.data[ii].titolo}</Button></p>
*/
        return (
          <div className="contenuto">
            <div className="seleziona">Seleziona un progetto</div>
            {html}
            <Logout ruolo={this.handleLogRole} />
          </div>
        )
      }
      else return (null)

    if (this.state.step==='casi') {
      let ii = this.state.progetto;
      return ( <GetCasi back={this.handleBack} titolo={this.state.data[ii].titolo} uid={this.props.uid} pid={this.state.data[ii].id} gid={this.state.data[ii].id_gruppo}  solo={false} /> )
    }
  }
}

class GetCasi extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cid: 0,
      tipo: 'default',
      titolo: '',
      data: false,
      step: 'casi',
    };
  }

  componentDidMount() {
    this.getCasi();
  }

  back = () => { this.props.back(true) }

  getCasi = () => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_casi_by_user', uid: this.props.uid, pid: this.props.pid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        for (let ii=0; ii<response.data.length; ii++) {
          response.data[ii].key = ii;
          if (response.data[ii].stato===null) response.data[ii].stato_text = 'Da completare';
          if (response.data[ii].stato==='2') response.data[ii].stato_text = 'Completato';
        }
        this.setState({data: response.data});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  handleBack = () => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'set_stato_caso', tipo:this.state.tipo, uid:this.props.uid, pid:this.props.pid, cid:this.state.cid}),
      responseType:'text'
    })
    .then(function(response) {
      //if (response.data==='') {
      //  warning('Ops, qualcosa è andato storto...')
      //} else {
        this.getCasi();
        this.setState({step: 'casi'});
      //}
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  startCaso = (record) => { this.setState({cid:record.id, titolo:record.titolo, tipo:record.tipo, step: 'sezioni'}) }

  render() {

    if(debug) console.log('GetCasi');

    let html = '';
    let indietro = (preview || this.props.solo) ? '' : <Row><Col xs={24}><Button type="primary" onClick={this.back}>Torna ai progetti</Button></Col></Row>
    if (this.state.step==='casi' && this.state.data)
      html =
        <div>
          <div className="progetto">{this.props.titolo}</div>
          <div className="seleziona">Seleziona un caso</div>
          <div className="contenuto">
            <Table
              bordered
              className="cursore"
              pagination={false}
              dataSource={this.state.data}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {this.startCaso(record)},
                };
              }}
            >
              <Column title="Caso" dataIndex="titolo" key="titolo" />
              <Column title="Autore" dataIndex="autore" key="autore" />
              <Column title="Stato" dataIndex="stato_text" key="stato_text" />
            </Table>
            <p />
            {indietro}
          </div>
        </div>

/*
            <Column title="Caso" key="titolo"
              render={(text, record) => ( <a onClick={() => this.startCaso(record)}>{record.titolo}</a> )}
            />

*/
    if (this.state.step==='sezioni' && this.state.tipo==="default")
      html =
        <GetSezioni back={this.handleBack} titolo={this.props.titolo} caso={this.state.titolo} cid={this.state.cid} uid={this.props.uid} pid={this.props.pid} gid={this.props.gid} />

    if (this.state.step==='sezioni' && this.state.tipo==="clickme")
      html =
        <GetClickme back={this.handleBack} titolo={this.props.titolo} caso={this.state.titolo} cid={this.state.cid} uid={this.props.uid} pid={this.props.pid} gid={this.props.gid} />

    return (
      <React.Fragment>
        {html}
      </React.Fragment>
    )
  }
}

class GetSezioni extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      sid: 0,
      titolo: '',
      data: false,
      step: 'sezioni'
    };
  }

  componentDidMount() {
    this.getSezioni();
  }

  startSezione = (record) => {
    this.setState({sid:record.id, titolo:record.titolo, step: 'scenari'});
  }

  getSezioni = () => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_sezioni_by_user', uid: this.props.uid, cid: this.props.cid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        for (let ii=0; ii<response.data.length; ii++) {
          response.data[ii].key = ii;
          if (response.data[ii].stato===null) response.data[ii].stato_text = 'Da sbloccare';
          if (ii===0) {
            if (response.data[ii].stato===null) {
              response.data[ii].stato = '1';
              response.data[ii].stato_text = 'Da completare';
            }
          } else {
            if (response.data[ii].stato===null && response.data[ii-1].stato==='2') {
              response.data[ii].stato = '1';
              response.data[ii].stato_text = 'Da completare';
            }
          }
          if (response.data[ii].stato==='2') response.data[ii].stato_text = 'Completato';
        }
        this.setState({data: response.data});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  handleBack = (set_stato) => {

    if (set_stato) {
      axios({
        method:'post',
        url:'php/progetti.php',
        data: qs.stringify({azione:'set_stato_sezione', uid: this.props.uid, pid: this.props.pid, cid: this.props.cid, sid: this.state.sid}),
        responseType:'text'
      })
      .then(function(response) {
        if (response.data==='') {
          warning('Ops, qualcosa è andato storto...')
        } else {
          this.getSezioni();
          this.setState({step: 'sezioni'});
        }
      }.bind(this))
      .catch(function (error) {
        warning('Ops, qualcosa è andato storto...',error.message);
        if (debug) console.log(error);
      });
    } else {
      this.setState({step: 'sezioni'});
    }
  }

  back = () => { this.props.back(true) }

  render() {

    if(debug) console.log('GetSezioni');

    let html = '';
    if (this.state.step==='sezioni' && this.state.data)
      html =
        <div>
          <div className="progetto">{this.props.titolo}</div>
          <div className="caso">{this.props.caso}</div>
          <div className="seleziona">Seleziona una sezione</div>
          <div className="contenuto">
            <Table
              bordered
              className="cursore"
              pagination={false}
              dataSource={this.state.data}
              onRow={(record, rowIndex) => {
                if (record.stato!==null)
                return {
                  onClick: event => {this.startSezione(record)},
                };
              }}
            >
              <Column title="Caso" dataIndex="titolo" key="titolo" />
              <Column title="Stato" dataIndex="stato_text" key="stato_text" />
            </Table>
            <p />
            <Row><Col xs={24}><Button type="primary" onClick={this.back}>Torna ai casi</Button></Col></Row>
          </div>
        </div>

/*
            <Column title="Caso" key="titolo"
              render={(text, record) => (
                record.stato!==null ? <a onClick={() => this.startSezione(record)}>{record.titolo}</a> : <span>{record.titolo}</span>
              )}
            />

*/
    if (this.state.step==='scenari')
      html =
        <GetScenari titolo={this.props.titolo} caso={this.props.caso} sezione={this.state.titolo} back={this.handleBack} sid={this.state.sid} uid={this.props.uid} pid={this.props.pid} gid={this.props.gid} />

    return (
      <React.Fragment>
        {html}
      </React.Fragment>
    )

  }
}

class GetScenari extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      risposte: false,
      accordo: true,
      tot_gruppo: 0,
      num_step: 0,
      step: 0
    };
  }

  componentDidMount() {
    this.getScenari();
  }

  componentDidUpdate() {
    if (!preview) setZoom();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getScenari = () => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_scenari', id: this.props.sid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({data: response.data, num_step: response.data.length});
        if (response.data[0].is_domanda==='1') {
          this.pollingGruppo(response.data[0].id);
          this.interval = setInterval(() => this.pollingGruppo(response.data[0].id), polling_rate);
        }
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_tot_gruppo', pid: this.props.pid, gid: this.props.gid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({tot_gruppo: response.data});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  pollingGruppo = (scenario) => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_risposte', sid: scenario, pid: this.props.pid, gid: this.props.gid, uid: this.props.uid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        let accordo = false;
        let voti_uguali = true;
        for (let ii=0; ii<response.data.length; ii++) {
          response.data[ii].key = ii;
          response.data[ii].utente = response.data[ii].nome+' '+response.data[ii].cognome;
          if (response.data[ii].risposta!==response.data[0].risposta) voti_uguali = false;
        }
        let singolo = this.props.gid==='0';
        let unanimita = this.state.data[this.state.step].unanimita==='1';
        let tutti_hanno_votato = this.state.tot_gruppo===response.data.length;
        if (singolo && response.data.length===1) accordo = true;
        if (!singolo && unanimita && tutti_hanno_votato && voti_uguali) accordo = true;
        if (!singolo && !unanimita && tutti_hanno_votato) accordo = true;
        if (debug) {
          console.log('singolo='+singolo)
          console.log('this.state.tot_gruppo='+this.state.tot_gruppo)
          console.log('response.data.length='+response.data.length)
          console.log('unanimita='+unanimita)
        }
        this.setState({risposte:response.data, accordo:accordo});
        if (accordo) clearInterval(this.interval); //accordo raggiunto, stop polling
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  back = () => {
    clearInterval(this.interval);
    this.props.back(false);
  }

  prec = () => {
    let step = this.state.step-1;
    if (step>=0) {
      clearInterval(this.interval);
      this.setState({step: step});
      if (this.state.data[step].is_domanda==='1') {
        this.pollingGruppo(this.state.data[step].id);
        this.interval = setInterval(() => this.pollingGruppo(this.state.data[step].id), polling_rate);
      } else {
        this.setState({accordo:true});
        //clearInterval(this.interval); //inutile, verificare
      }
    }
  }

  succ = () => {
    let step = this.state.step+1;
    if (step<this.state.num_step) {
      this.setState({step: step});
      if (this.state.data[step].is_domanda==='1') {
        this.pollingGruppo(this.state.data[step].id);
        this.interval = setInterval(() => this.pollingGruppo(this.state.data[step].id), polling_rate);
      }
    } else {
      clearInterval(this.interval);
      this.props.back(true);
    }
  }

  refresh = (r) => { this.pollingGruppo(this.state.data[this.state.step].id); }

  render() {

    if(debug) console.log('GetScenari');

    if (this.state.data.length>0) {

      let d = this.state.data[this.state.step];
      let gruppo = '';
      let domanda = '';
      let accordo = '';
      let cln = 24;
      if (d.is_domanda==='1') {
        domanda = <GetDomanda qid={d.id} uid={this.props.uid} pid={this.props.pid} gid={this.props.gid} refresh={this.refresh} accordo={this.state.accordo} />
        if (this.props.gid!=='0') {
          //cln = 12;
          cln = 24;
          if (this.state.risposte) {
            gruppo =
              <Table bordered size={'middle'} pagination={false} dataSource={this.state.risposte}>
                <Column title="Utente" dataIndex="utente" key="utente" />
                <Column title="Risposta" dataIndex="testo_risposta" key="testo_risposta" />
              </Table>
            if (this.state.data[this.state.step].unanimita==='1')
              accordo = this.state.accordo ? 'Accordo raggiunto!' : 'Accordo non ancora raggiunto';
            else
              accordo = this.state.accordo ? 'Votazione conclusa' : 'Votazione in corso';
          }
        }
      }

      let btn_indietro = this.props.solo ? '' : <Button type="primary" onClick={this.back}>Torna alle sezioni</Button>

      //let btn_suc_txt = ((this.state.step+1)===this.state.num_step) ? 'Concludi' : 'Successivo';
      let btn_suc_txt = 'Successivo';
      if ((this.state.step+1)===this.state.num_step) {
        btn_suc_txt = 'Concludi';
        btn_indietro = '';
      }
      let btn_successivo = <Button type="primary" disabled>{btn_suc_txt}</Button>
      if (this.state.accordo) btn_successivo = <Button type="primary" onClick={this.succ}>{btn_suc_txt}</Button>

      if ((this.state.step+1)===this.state.num_step && this.props.solo) btn_successivo = ''

      //ho tolto il titolo delo scenario (riga 806, riga 812 con questo commento)
      /*
              <p />
              <div><strong>{d.titolo}</strong></div>
              <p />
      */
      let html =
        <div className="html">
          <p />
          <Row gutter={16} className="spazia2">
            <Col xs={0} sm={7}>&nbsp;</Col>
            <Col xs={10} sm={4}><Button type="primary" onClick={this.prec}>Precedente</Button></Col>
            <Col xs={4}  sm={2} className="conta-scenari">{this.state.step+1}/{this.state.num_step}</Col>
            <Col xs={10} sm={4}>{btn_successivo}</Col>
            <Col xs={0} sm={7}>&nbsp;</Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={cln} className="domanda">
              <div dangerouslySetInnerHTML={createMarkup(d.testo)} />
              {domanda}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={cln}>
              {gruppo}
              <div className="accordo">{accordo}</div>
            </Col>
          </Row>
          <Row><Col xs={24}>{btn_indietro}</Col></Row>
        </div>

      return (
        <React.Fragment>
          <div className="progetto">{this.props.titolo}</div>
          <div className="caso">{this.props.caso}</div>
          <div className="sezione">{this.props.sezione}</div>
          <div className="contenuto">{html}</div>
        </React.Fragment>)

    } else {
      return (null);
    }
  }
}

class GetDomanda extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: false,
      scelta: 0
    };

    this.getDomanda(this.props.qid);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.qid!==nextProps.qid) this.getDomanda(nextProps.qid);
    return true;
  }

  getDomanda = (qid) => {

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'get_domanda', id: qid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({data: response.data});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onChange = (e) => {

    this.setState({scelta: e.target.value});

    axios({
      method:'post',
      url:'php/progetti.php',
      data: qs.stringify({azione:'set_risposta', sid: this.props.qid, pid: this.props.pid, gid: this.props.gid, uid: this.props.uid, risposta: e.target.value}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.props.refresh(true);
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });

  }

  render() {

    if(debug) console.log('GetDomanda');

      if (this.state.data) {

        //let stato = false;
        //if (this.props.accordo) stato = true;
        let risposte = [];
        for (let ii=0; ii<this.state.data.length; ii++)
          risposte[ii] = <Radio disabled={this.props.accordo} key={ii} style={radioStyle} value={this.state.data[ii].id}>{this.state.data[ii].risposta}</Radio>

        return (
          <div>
            <RadioGroup onChange={this.onChange} value={this.state.scelta}>
              {risposte}
            </RadioGroup>
          </div>
        )

      } else {
        return (null);
      }

  }
}

class GetClickme extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      scenari: false,
      terapie: false,
      terapia: 0
    };

    this.getScenari();
  }

  back = () => { this.props.back(true) }

  getScenari = () => {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_scenari', id: this.props.cid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({scenari: response.data});
        this.getTerapie();
        this.getNumParametri();
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  getTerapie = () => {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_terapie', cid:this.props.cid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({terapie:response.data});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  getNumParametri = () => {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'num_parametri', cid:this.props.cid}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({num_parametri:response.data});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  saveScenario = (e) => {

    e.preventDefault();
    let opzioni = '';
    let elements = document.forms['form-1'].elements;

    let num_parametri = 0;
    for (let ii=0; ii<elements.length; ii++)
      if(elements[ii].checked) {
        num_parametri++;
        opzioni += elements[ii].value + ',';
      }

    if (num_parametri<this.state.num_parametri) {
      warning ("Imposta tutti i parametri dello scenario!");
      return;
    }

    if ((this.state.terapie.length!==0) && (this.state.terapia===0)) {
      warning ("Scegli un trattamento!");
      return;
    }

    opzioni = opzioni.substr(0, opzioni.length-1);
    this.setState({opzioni:opzioni});

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'save_scenario', opz:opzioni, pid:this.props.pid, cid:this.props.cid, uid:this.props.uid, tid:this.state.terapia}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        if (response.data==='ok') warning("Dati inviati correttamente.");
        if (response.data==='max') warning("Puoi inviare solo 3 scenari!");
        if (response.data==='once') warning("Hai già inviato questo scenario!");
      }
    })
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onChangeTerapia = (e) => {
    this.setState({terapia: e.target.value});
  }

  render() {

    if(debug) console.log('GetDomanda');

    let step1 = '';
    if (this.state.scenari) {
      let html = [];
      for (let ii=0; ii<this.state.scenari.length; ii++)
        html[ii] =
          <div key={ii}>
            <h4><strong>{this.state.scenari[ii].nome}</strong></h4>
            <GetParametriScenarioClickme scenario={this.state.scenari[ii].id} />
          </div>

      step1 =
        <div className="spazia">
          <Form id="form-1">
            {html}
          </Form>
        </div>
    }

    let step2 = '';
    let trattamenti = '';
    if (this.state.terapie.length!==0) {
      let sol = [];
      for (let ii=0; ii<this.state.terapie.length; ii++)
        sol[ii] =
          <Row key={ii}>
            <Col><Radio name={'terapia'} value={this.state.terapie[ii].id}> {this.state.terapie[ii].nome}</Radio></Col>
          </Row>
      trattamenti =
        <React.Fragment>
          <h4><strong>Scegli il trattamento:</strong></h4>
          <div className="spazia">
            <RadioGroup onChange={this.onChangeTerapia} value={this.state.terapia}>
              {sol}
            </RadioGroup>
          </div>
        </React.Fragment>
    }

    step2 =
      <div className="spazia">
        <Form id="form-2" onSubmit={this.saveScenario}>
          {trattamenti}
          <Button type="primary" htmlType="submit">Invia</Button>
        </Form>
      </div>

    let btn_indietro = this.props.solo ? '' : <Button type="primary" onClick={this.back}>Torna ai casi</Button>

    return (
      <div>
        <div className="progetto">{this.props.titolo}</div>
        <div className="caso">{this.props.caso}</div>
        <div className="contenuto left">
          <div className="left">
            {step1}
            {step2}
          </div>
          <Row><Col xs={24}>{btn_indietro}</Col></Row>
        </div>
      </div>
    );
  }
}

class GetParametriScenarioClickme extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      data:false
    };
  }

  componentDidMount() {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_parametri', id: this.props.scenario}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({data: response.data});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  render() {

    if(debug) console.log('GetParametriScenarioClickme');

    var html = [];
    if (this.state.data)
      for (var ii=0; ii<this.state.data.length; ii++)
        html[ii] =
          <Row key={ii}>
            <Col xs={24} md={ 4}><em>{this.state.data[ii].nome}</em></Col>
            <Col xs={24} md={20}><GetOpzioniScenarioClickme parametro={this.state.data[ii].id} /></Col>
          </Row>

    return (
      <div>{html}</div>
    );
  }

}

class GetOpzioniScenarioClickme extends React.Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      data:false,
      scelta:0
    };
  }

  componentDidMount() {

    axios({
      method:'post',
      url:'php/clickme.php',
      data: qs.stringify({azione:'get_opzioni', id: this.props.parametro}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='') {
        warning('Ops, qualcosa è andato storto...')
      } else {
        this.setState({data: response.data});
      }
    }.bind(this))
    .catch(function (error) {
      warning('Ops, qualcosa è andato storto...',error.message);
      if (debug) console.log(error);
    });
  }

  onChange = (e) => {
    this.setState({scelta: e.target.value});
  }

  render() {

    if(debug) console.log('GetOpzioniScenarioClickme');

    var html = [];
    if (this.state.data)
      for (var ii=0; ii<this.state.data.length; ii++)
        html[ii] =
          <Row key={ii}>
            <Radio name={'opz-'+this.props.parametro} value={this.state.data[ii].id} required>{this.state.data[ii].nome}</Radio>
          </Row>

    return (
      <RadioGroup onChange={this.onChange} value={this.state.scelta}>
        {html}
      </RadioGroup>
    );
  }

}

//export default UserContainer;
export {Preview, PlayProgettoSolo, PlayScenariSolo, UserContainer};
